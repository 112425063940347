.app-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./../public/assets/background.png'); // Fondo con relieve
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    z-index: -1;
}

.music-toggle {
    position: absolute;
    top: 20px;
    left: 20px;
    background: none;
    border: none;
    cursor: pointer;
    width: 50px;
    height: 50px;
    z-index: 10;
}

.music-toggle img {
    width: 100%;
    height: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 95%;
    gap: 20px;
}

img {
    width: auto;
}

.buttons {
    display: flex;
    gap: 20px;
}

.location-button {
    padding: 10px 10px;
    font-size: 14px;
    border: 2px solid #8B5A2B; // Marrón oscuro
    color: #704623;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.enter-button {
    padding: 10px 20px;
    font-size: 20px;
    border: 2px solid #8B5A2B; // Marrón oscuro
    color: #704623;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.confirm-button {
    padding: 10px 20px;
    font-size: 16px;
    border: 2px solid #8B5A2B; // Marrón oscuro
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.confirm-button.yes {
    background-color: #8B5A2B; // Marrón sólido para "Sí"
    color: #fff;
    border-color: #8B5A2B;
}

.confirm-button.yes:hover {
    background-color: #704623; // Marrón más oscuro al hover
    border-color: #704623;
}

.confirm-button.no {
    background-color: transparent;
    color: #8B5A2B;
}

.confirm-button.no:hover {
    background-color: #8B5A2B; // Marrón sólido al hover
    color: #fff;
}

.question-text {
    padding: 0;
    margin: 5px;
    font-size: 20px;
    font-weight: bold;
    color: #8B5A2B; // Marrón que combina con el fondo
}

/* Animaciones */
.animate-from-left {
    animation: slide-in-left 1s ease-out forwards;
}

.animate-from-right {
    animation: slide-in-right 1s ease-out forwards;
}

.animate-from-top {
    animation: slide-in-top 1s ease-out forwards;
}

.animate-from-bottom {
    animation: slide-in-bottom 1s ease-out forwards;
}

/* Definición de animaciones */
@keyframes slide-in-left {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-right {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-top {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-bottom {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}